import React from "react";
import { PageProps } from "gatsby";

export default function FourOhFourPage(props: PageProps) {
  return (
    <>
      <h1>404</h1>
      <div>This page doesn't exist</div>
    </>
  );
}
